import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Step from './components/texts/Step';
import Title from './components/texts/Title';
import CircularProgress from './components/CircularProgress';
import Button from './components/Button';
import Description from './components/texts/Description';

import connectionGif from './images/connection.gif';
import ConnectCSV from './components/svg/Connect';
import ConnectedCSV from './components/svg/Connected';
import BootloadSVG from './components/svg/Bootload';
import DoneSVG from './components/svg/Done';
import VerifySVG from './components/svg/Verify';
import ErrorSVG from './components/svg/Error';

import { Workflow, STEPS, INITIAL_STEP, CONNECT_STEP, CONNECTED_STEP, DONE_STEP, BUTTON_AVAILABLE, CONNECT_TO_BOOTLOADER_STEP, CONNECT_TO_FIRMWARE_STEP, VERIFY_FIRMWARE_STEP, BOOTLOAD_STEP, ERROR_BROWSER, ERROR_UNKNOWN, UNPLUG_STEP, REPLUG_STEP } from './workflow/workflow';
import LogoSVG from './components/svg/Logo';
import LaptopSVG from './components/svg/Laptop';
import { CONNECT } from './serial/names';
import UnplugSVG from './components/svg/Unplug';
import ReplugSVG from './components/svg/Replug';


const Main = styled.main`
  flex : 1;
  width : 100%;
  display : flex;
  flex-direction : column;
  justify-content : space-between;
  align-items : center;
  background: #F5FCFF;
`;

const Header = styled.nav`
  flex : 0;
  flex-basis : 6rem;
  height : 6rem;
  width : 100%;
  box-shadow: 0px 1px 4px 0px #0000000D;
  background-color : white;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;
`;

const WorkflowContainer = styled.div`
  flex : 1;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : flex-end;
  width : 100%;

  @media only screen and (max-width: 470px) {
    display : none;
  }
`;

const MobileContaier = styled.div`
  flex : 1;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  width : 100%;

  @media only screen and (min-width: 471px) {
    display : none;
  }
`;

const MobileTitle = styled.div`
  font-size : 16px;
  line-height : 30px;
  margin-left : 3rem;
  margin-right : 3rem;
  margin-bottom : 10rem;
  text-align : center;
`;

const MobileLaptopSVG = styled(LaptopSVG)`
  position : relative;
`;

const HeaderLink = styled.a`
  position : absolute;
  right : 2rem;
  height : 6rem;
  line-height : 6rem;
  vertical-align : center;
  font-size : 1.2rem;
  text-transform : uppercase;
  color : #222222;
  text-decoration : none;
  outline : none;

  &:hover, &:active, &:focus {
    text-decoration : underline;
  }

  @media only screen and (max-width: 550px) {
    display : none;
  }
`


const StepContainer = styled.div`
  position : relative;
  margin-bottom : 4.4rem;
  width : 100%;
`;

const TitleContainer = styled.div`
  position : relative;
  height : 3rem;
  margin-bottom : 20rem;
  width : 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  position : relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom : 2rem;
  height : 21rem;
  width : 100%;
`

const DescriptionContainer = styled.div`
  position : relative;
  height : 6rem;
  margin-bottom : 3rem;
  width : 100%;
  display : flex;
  align-items : center;
  justify-content : center;
`

const ConnectionGif = styled.img`
  position : absolute;
  height : 21rem;
  width : 22rem;
  opacity : 0;
  transition : opacity 0.8s ease;

  &.visible {
    opacity : 1;
  }
`

const PositionedButton = styled(Button)`
  position :relative;
  z-index : 10000;
  margin-bottom : 9.9rem;
`

const ErrorBottomLine = styled.div`
  position : absolute;
  bottom : 6rem;
  font-size : 1.4rem;
  color : #A0A0A0;

  & a {
    color : #A0A0A0;
    text-decoration : none;
    font-weight : bolder;
    text-transform : uppercase;
  }

  & a:hover, & a:active, & a:focus {
    color : #A0A0A0;
    text-decoration : underline;
  }
`;



function App() {
  const {t} = useTranslation();

  /**
   * Workflow initialization
   */
  const workflow = useRef<Workflow>(new Workflow());
  useEffect(() => {
    workflow.current.on('error', ({step}) => {
      setError(step)
      if(step === ERROR_BROWSER) {
        setStep(CONNECT_STEP)
      }
    });
    workflow.current.on('state', ({step, progress, branch = ''}) => {setError(0); setStep(step); setProgress(progress); setBranch(branch)});
    workflow.current.start();
  }, []);

  const [step, setStep] = useState<number>(INITIAL_STEP);
  const [branch, setBranch] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<number>(0);

  const handleButtonClick = () => {
    workflow.current.next();
  }

  const handleWorkflowReset = () => {
    window.location.reload();
    // workflow.current.start();
  }

  return (
    <Main>
      <Header>
        <LogoSVG />
        <HeaderLink href={'https://lightinderm.com/mon-compte/my-lightinderm/'}>{t('header.link')}</HeaderLink>
      </Header>
      <WorkflowContainer>
        {/* <StepContainer>
          {[...new Array(STEPS).keys()].map(
            index => <Step visible={!error && step === index}>{t('steps.step' + index)}</Step>
          )}
          <Step visible={!!error}>{t('steps.step-error')}</Step>
        </StepContainer> */}
        <TitleContainer>
          {[...new Array(STEPS).keys()].map(
            index => <Title visible={!error && step === index}>{t('titles.title' + index)}</Title>
          )}
          <Title visible={!!error}>{t('titles.title-error' + error)}</Title>
        </TitleContainer>
        <CircularProgress
          progress={progress}
          step={step}
          steps={STEPS}
          error={!!error}
          >
        </CircularProgress>
        <ImageContainer>
          <ConnectCSV visible={!error && step === INITIAL_STEP}/>
          <ConnectionGif src={connectionGif} className={!error && (step === CONNECT_STEP || step === CONNECT_TO_BOOTLOADER_STEP || step === CONNECT_TO_FIRMWARE_STEP) ? 'visible' : ''}/>
          <ConnectedCSV visible={!error && step === CONNECTED_STEP}/>
          <BootloadSVG visible={!error && step === BOOTLOAD_STEP}/>
          <VerifySVG visible={!error && step === VERIFY_FIRMWARE_STEP}/>
          <DoneSVG visible={!error && step === DONE_STEP} />
          <UnplugSVG visible={!error && step === UNPLUG_STEP} />
          <ReplugSVG visible={!error && step === REPLUG_STEP} />
          <ErrorSVG visible={error === ERROR_UNKNOWN} />
          <LaptopSVG visible={error === ERROR_BROWSER} />
        </ImageContainer>
        <DescriptionContainer>
          {[...new Array(STEPS).keys()].map(
            index => <Description visible={!error && step === index} value={t('descriptions.description' + index + branch)} />
          )}
          <Description visible={error === ERROR_UNKNOWN} value={t('descriptions.description-error')} />
          <Description visible={error === ERROR_BROWSER} value={t('descriptions.description-error-browser')} />
        </DescriptionContainer>
        {
          !error ?
            <PositionedButton onClick={handleButtonClick} visible={BUTTON_AVAILABLE[`${step}`]}>{t('buttons.button' + step)}</PositionedButton> :
          error === ERROR_BROWSER ?
            <PositionedButton onClick={()=>{}} visible={false}></PositionedButton> :
            <React.Fragment>
              <PositionedButton onClick={handleWorkflowReset} visible>{t('buttons.button-error')}</PositionedButton>
              <ErrorBottomLine dangerouslySetInnerHTML={{__html : t('errorBottomLine')}}/>
            </React.Fragment>
        }
      </WorkflowContainer>
      <MobileContaier>
        <MobileTitle>{t('mobileContent')}</MobileTitle>
        <MobileLaptopSVG visible={true} />
      </MobileContaier>
    </Main>
  );
}

export default App;