import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './fr';

i18next
.use(initReactI18next)
.init({
  lng: 'fr', // if you're using a language detector, do not define the lng option
  resources: {
    fr
  }
});