import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  position : absolute;
  font-size : 1.8rem;
  line-height : 4rem;
  color: #222222;
  text-align : center;
  opacity : 0;
  transition : opacity 0.8s ease;
  margin-left : 3rem;
  margin-right : 3rem;
  font-weight : normal;

  &.visible {
    opacity : 1;
  }
`;

const Title : React.FC<TitleProps> = ({visible, ...props}) => {
  return (
    <Text {...props}  className={visible ? 'visible' : ''}/>
  );
}

interface TitleProps {
  visible : boolean;
}

export default Title;