import InputBuffer from "./inputbuffer";

import commands from "./commands";
import * as Names from "./names";
import { HexFile } from "./types";
import { chunk } from "lodash";

const TIMEOUT = 5000;
const INITIAL_ADDRESS = "1200";
const MAX_RETRY = 3;

class LidSerial {
  valid: boolean;
  newMobo: boolean;
  private port: SerialPort | null;

  private callbacks: Record<string, Callback> = {};
  private buffer: InputBuffer = new InputBuffer();
  private timeout: ReturnType<typeof setTimeout> | null = null;

  private commands: Record<string, Command>;
  private ongoing: Task | null;

  private reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  private writer: WritableStreamDefaultWriter<Uint8Array> | null = null;
  private keepreading: boolean = false;

  constructor() {
    this.valid = !!navigator.serial;
    this.port = null;
    this.commands = commands;
    this.ongoing = null;
    this.defaultOnRead = this.defaultOnRead.bind(this);
    this.defaultOnTimeout = this.defaultOnTimeout.bind(this);
    this.newMobo = false;
  }

  /****************************
   * Assertions
   */
  private isEnabled() {
    if (!this.valid) {
      throw new Error(`Browser not supported`);
    }
  }

  private isOpen() {
    if (!this.port || !this.port?.readable || !this.port?.writable) {
      throw new Error(`Serial port is closed`);
    }
  }

  /****************************
   * API
   */
  async open(baudRate: number = 19200) {
    try {
      this.isEnabled();

      this.port = await navigator.serial.requestPort({
        filters: [
          { usbProductId: 0xdf, usbVendorId: 0x04d8 },
          { usbProductId: 0x6015, usbVendorId: 0x0403 },
        ],
      });

      await this.port.open({ baudRate });
      this.newMobo = this.port?.getInfo().usbProductId === 0x6015;
      this.emitEvent("open", {});
      this.keepreading = true;
      this.readLoop();
    } catch (error) {
      return this.emitError(error);
    }
  }

  async close() {
    try {
      this.isEnabled();

      this.keepreading = false;

      if (this.reader) {
        await this.reader.cancel();
      }
      if (this.writer) {
        this.writer.releaseLock();
      }

      await this.port?.close();

      this.emitEvent("close", {});
    } catch (error) {
      return this.emitError(error);
    } finally {
      this.port = null;
      this.reader = null;
      this.writer = null;
      this.buffer = new InputBuffer();
      this.ongoing = null;
    }
  }

  async execute(
    command: string,
    values?: Record<string, Uint8Array | string>
  ): Promise<CommandResult> {
    // Promise chaining should be handled in userspace
    if (this.ongoing) {
      return this.emitError("Busy");
    }

    // Throw imediately if the command does not exists
    if (!this.commands[command]) {
      return this.emitError(`Unable to find command ${command}`);
    }

    const promise = new Promise<CommandResult>((resolve, reject) => {
      this.ongoing = {
        resolve,
        reject,
        command: this.commands[command],
        values,
      };
    });

    await this.send(this.commands[command].onWrite(values));

    return promise;
  }

  async bootload(hex: HexFile) {
    await this.execute(Names.BOOTLOADER_CHECK);
    await this.execute(Names.BOOTLOADER_SET_ADDRESS);

    const validRows = hex.filter(
      (row) =>
        row.type === "00" &&
        row.parsedSize === 16 &&
        row.address >= INITIAL_ADDRESS
    );
    const chunks = chunk(validRows, 4);

    let progress = 0;
    for (let rows of chunks) {
      const address = rows[0].address;
      const data = rows.map(({ data }) => data).join("");

      let iteration = 1;
      let done = false;

      while (!done && iteration <= MAX_RETRY) {
        try {
          await this.execute(Names.BOOTLOADER_WRITE_CHUNK, { address, data });
          done = true;
        } catch (error) {
          this.emitWarn(error, Names.BOOTLOADER_WRITE_CHUNK);
          iteration++;
        }
      }

      this.emitEvent("progress", {
        parsedData: {
          progress: Math.round((++progress / chunks.length) * 100),
        },
      });
    }

    await this.execute(Names.BOOTLOADER_CLOSING_1);
    await this.execute(Names.BOOTLOADER_CLOSING_2);
  }

  /****************************
   * Low level RW functions
   */
  async send(data: Uint8Array) {
    try {
      this.isEnabled();
      this.isOpen();

      if (this.port && this.port.writable) {
        this.writer = this.port.writable.getWriter();
        await this.writer.write(data);
        // const decoder = new TextDecoder('ascii');
        // console.log('after write', [...(data?.values() || [])].map(byte => byte.toString(16)), decoder.decode(data));

        this.startTimeout();

        this.writer.releaseLock();
        this.writer = null;

        this.emitEvent("sent", {
          data,
          command: this.ongoing?.command?.name,
        });
      }
    } catch (error) {
      return this.emitError(error, this.ongoing?.command?.name);
    }
  }

  private async readLoop() {
    while (this.port?.readable && this.keepreading) {
      try {
        this.reader = this.port.readable.getReader();

        while (true) {
          const { value, done } = await this.reader.read();
          // const decoder = new TextDecoder('ascii');
          // console.log('receive', [...(value?.values() || [])].map(byte => byte.toString(16)), decoder.decode(value));

          if (done) {
            break;
          }
          if (value) {
            this.clearTimeout();
            this.buffer.push(value);

            if (this.ongoing) {
              const { command, resolve, reject } = this.ongoing;
              const keep = command.onData(
                this.buffer.data,
                this.ongoing.values
              );

              // If keep != -1, then the buffer have been accepted as a
              // valid response to the command request.
              if (keep >= 0) {
                const data = this.buffer.shift(keep);
                const result = (command.onRead || this.defaultOnRead)(
                  data,
                  this.ongoing.values
                );

                // If there is an onoing commaction, emit received event only when
                // the response have been accepted by che command onData handler
                this.emitEvent("received", {
                  command: this.ongoing.command.name,
                  data,
                });

                if (result.error) {
                  this.emitError(result.error, command.name);
                  reject(result.error);
                } else {
                  resolve({
                    command: this.ongoing.command.name,
                    data,
                    parsedData: result.parsedData,
                  });
                }

                this.ongoing = null;
              } else {
                this.startTimeout();
              }
            } else {
              const data = this.buffer.shift(this.buffer.size);
              this.emitEvent("received", { data });
            }
          }
        }
      } catch (error) {
        this.emitError(error, `${error}`);
      } finally {
        this.reader?.releaseLock();
        this.reader = null;
      }
    }
  }

  private defaultOnRead(data: Uint8Array): ReadHandlerResult {
    return {};
  }

  private defaultOnTimeout(data: Uint8Array): ReadHandlerResult {
    this.clearTimeout();

    return {
      error: "Timeout",
    };
  }

  /****************************
   * Timeout handling
   */
  startTimeout() {
    this.clearTimeout();
    this.timeout = setTimeout(() => this.handleTimeout(), TIMEOUT);
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  handleTimeout() {
    this.clearTimeout();

    if (this.ongoing) {
      const data = this.buffer.shift(this.buffer.size);
      const result = (this.ongoing.command.onTimeout || this.defaultOnTimeout)(
        data,
        this.ongoing.values
      );

      if (data.length) {
        this.emitEvent("received", {
          command: this.ongoing.command.name,
          data,
        });
      }

      if (result.error) {
        this.emitEvent("timeout", { command: this.ongoing.command.name, data });
        this.ongoing.reject(result.error);
      } else {
        this.ongoing.resolve({
          ...result,
          command: this.ongoing.command.name,
          data,
        });
      }

      this.ongoing = null;
    }
  }

  /****************************
   * Event handling
   */

  on(event: string, callback: Callback) {
    this.callbacks[event] = callback;
  }

  private emitError(error: any, command?: string) {
    this.callbacks["error"] &&
      this.callbacks["error"]({ command, error: `${error}` });
    return Promise.reject(error);
  }

  private emitWarn(error: any, command?: string) {
    this.callbacks["warn"] &&
      this.callbacks["warn"]({ command, error: `${error}` });
    return Promise.reject(error);
  }

  private emitEvent(event: string, args: CallbackEvent) {
    this.callbacks[event] && this.callbacks[event](args);
  }
}

type SerialEnabledNavigator = {
  serial: Serial;
};
declare const navigator: SerialEnabledNavigator;

export type Command = {
  name: string;
  onWrite: (values?: Record<string, Uint8Array | string>) => Uint8Array;
  onData: (
    data: Uint8Array,
    values?: Record<string, Uint8Array | string>
  ) => number;
  onRead?: (
    data: Uint8Array,
    values?: Record<string, Uint8Array | string>
  ) => ReadHandlerResult;
  onTimeout?: (
    data: Uint8Array,
    values?: Record<string, Uint8Array | string>
  ) => ReadHandlerResult;
};

export type ReadHandlerResult = {
  error?: string;
  parsedData?: Record<string, string | number>;
};

type Task = {
  command: Command;
  resolve: (result: CommandResult) => void;
  reject: (reason?: any) => void;
  values?: Record<string, Uint8Array | string>;
};

// Type resolved by execute promises
export type CommandResult = {
  command?: string;
  error?: string;
  data?: Uint8Array;
  parsedData?: Record<string, string | number>;
};

// Type of event emitted
export type CallbackEvent = CommandResult;

export type Callback = (result: CallbackEvent) => void;

export default LidSerial;
