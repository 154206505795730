import React from 'react';
import styled from 'styled-components';

import Bubble from './Bubble';

import { INITIAL_STEP, CONNECT_STEP, CONNECTED_STEP, DONE_STEP } from '../workflow/workflow';

const Container = styled.div`
  bottom : 0;
  height : 52rem;
  position : absolute;
  display : flex;
  flex-direction : column;
  align-items : center;
`;

const RotatingContainer = styled.div`
  position : absolute;
  transform-origin : bottom center;
  height : 52rem;
  bottom : 0px;
  transition : transform 0.8s ease;
`;

const RotatingSvgContainer = styled(RotatingContainer)`
  overflow : hidden;
`;

const Svg = styled.svg`
  height : 52rem;
  transform-origin : bottom center;
`;


const CircularProgress : React.FC<CircularProgressProps> = ({step, steps, progress, error, ...props}) => {
  let stepRotation : number = 0;
  let progressRotation : number = -180;

  if(error) {
    stepRotation = 0
  }
  else {
    switch(step) {
      case INITIAL_STEP:
        stepRotation = 180;
        break;
      case CONNECT_STEP:
        stepRotation = 90;
        break;
      case CONNECTED_STEP:
        stepRotation = 30;
        break;
      case DONE_STEP:
        stepRotation = -90;
        break;
      case steps - 2:
        stepRotation = -30;
        break;
      default:
        stepRotation = 0;
    }
  }

  if(error) {
    progressRotation = -180
  }
  else {
    switch(step) {
      case INITIAL_STEP:
      case CONNECT_STEP:
        progressRotation = -180;
        break;
      case CONNECTED_STEP:
        progressRotation = Math.round(-180 + 60*progress)
        break;
      case DONE_STEP:
        progressRotation = 0;
        break;
      case steps-2:
        progressRotation = Math.round(-120 + 60*progress);
        break;
      default:
        progressRotation = Math.round(-150 + 60*progress);
    }
  }

  function computeBubbleRotation(current : number) {
    if(error) {
      return current === step ? 90 : current < step ? -30 : 210
    }

    if(step === INITIAL_STEP) {
      return 185;
    }
    else { // (step > INITIAL_STEP) {
      return (
        current === step ? 90 :
        current === step - 1 ? 30 :
        current === step + 1 ? 150 :
        current < step - 1 ? -30 :
        210
      )
    }
  }

  return (
    <Container>
      <RotatingSvgContainer style={{transform : `rotate(${stepRotation}deg)`}}>
        <Svg viewBox="0 0 966 483" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M963 483C963 217.903 748.097 3 483 3C217.903 3 3 217.903 3 483" stroke="#EBE9E7" stroke-width="0.5rem"/>
        </Svg>
        <RotatingSvgContainer style={{transform : `rotate(${progressRotation}deg)`}}>
          <Svg viewBox="0 0 966 483" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M963 483C963 217.903 748.097 3 483 3C217.903 3 3 217.903 3 483" stroke="#222222" stroke-width="0.5rem"/>
          </Svg>
        </RotatingSvgContainer>
      </RotatingSvgContainer>
      {
        [...new Array(steps-1).keys()].map(k => k+1).map(index => (
          <RotatingContainer key={index} style={{transform : `rotate(${computeBubbleRotation(index)}deg)`}}>
            <Svg viewBox="0 0 966 483" fill="none" xmlns="http://www.w3.org/2000/svg" />
            <Bubble rotation={computeBubbleRotation(index)} type={index < step ? 'ok' : index > step ? 'empty' : error ? 'error' : progress < 1 ? 'ongoing' : 'ok'} />
          </RotatingContainer>
        ))
      }
    </Container>
  );
}

interface CircularProgressProps {
  step : number;
  steps : number;
  progress : number;
  error : boolean;
}

export default CircularProgress;