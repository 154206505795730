import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  position : absolute;
  font-size : 1.4rem;
  font-weight : 700;
  line-height : 2rem;
  color: #222222;
  width : 50rem;
  height : 100%;
  text-align : center;
  display : flex;
  flex-direction : column;
  justify-content : center;
  opacity : 0;
  transition : opacity 0.8s ease;

  &.visible {
    opacity : 1;
  }
`;

const Description : React.FC<DescriptionProps> = ({visible, value, ...props}) => {
  return (
    <Text {...props} className={visible ? 'visible' : ''} dangerouslySetInnerHTML={{__html : value}}/>
  );
}

interface DescriptionProps {
  visible : boolean;
  value : string;
}

export default Description;