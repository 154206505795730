import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const SVG = styled.svg`
  position : absolute;
  width : 100%;
  opacity : 0;
  transition : opacity 0.8s ease;

  &.visible {
    opacity : 1;
  }
`

const LaptopSVG : React.FC<{visible : boolean, className ?: string}>= ({visible, className = ''}) => {
  return (
    <SVG className={classNames([className, visible ? 'visible' : ''])} width="24.7rem" height="15.9rem" viewBox="0 0 247 159" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7018 0C28.2093 0 26.1887 2.02766 26.1887 4.5289V131.101C26.1887 133.603 28.2093 135.63 30.7018 135.63H216.298C218.791 135.63 220.811 133.603 220.811 131.101V4.52891C220.811 2.02766 218.791 0 216.298 0H30.7018ZM32.636 6.46987V129.16H214.364V67.8151V6.46987H32.636Z" fill="#222222"/>
      <path d="M246.999 154.287C247.014 153.473 246.654 152.645 245.831 152.103L221.455 136.055C221.245 135.916 221.017 135.81 220.779 135.739C220.541 135.667 220.292 135.63 220.041 135.63H26.9591C26.4564 135.63 25.9646 135.778 25.5443 136.055L1.16893 152.103C0.334067 152.653 -0.0241545 153.496 0.00125929 154.32V157.059C0.00125929 158.131 0.867236 159 1.93547 159H245.066C246.134 159 247 158.131 247 157.059L246.999 154.287Z" fill="#222222"/>
      </g>
    </SVG>
  );
}

export default LaptopSVG;
