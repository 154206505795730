const INITIAL_LENGTH = 16;

class InputBuffer {
  private buffer : Uint8Array = new Uint8Array(INITIAL_LENGTH);
  private length : number = 0;

  push(data :Uint8Array) {
    if(!data.length) {return;}

    this.store(data);
  }

  shift(length : number) {
    const _length = Math.min(length, this.length);

    const result = this.buffer.slice(0, _length);
    this.buffer = this.buffer.slice(_length);
    this.length -= _length;
    return result;
  }

  get size() {
    return this.length;
  }

  get data() {
    return this.buffer.slice(0, this.length);
  }

  private store(data :Uint8Array) {
    // Increase buffer length if necessary
    if(this.buffer.length < this.length + data.length) {
      const newBuffer = new Uint8Array(this.length + data.length);
      newBuffer.set(this.buffer);
      this.buffer = newBuffer;
    }

    // Copy data into buffer
    this.buffer.set(data, this.length);
    this.length += data.length;
  }
}

export default InputBuffer;