import LidSerial from "../serial/serial";

import configProvider from "./configprovider";
import * as Commands from "../serial/names";
import { loadHex } from "../serial/hex";

export const INITIAL_STEP = 0;
export const CONNECT_STEP = 1;
export const CONNECTED_STEP = 2;
export const CONNECT_TO_BOOTLOADER_STEP = 3;
export const BOOTLOAD_STEP = 4;
export const CONNECT_TO_FIRMWARE_STEP = 5;
export const VERIFY_FIRMWARE_STEP = 6;
export const UNPLUG_STEP = 7;
export const REPLUG_STEP = 8;
export const DONE_STEP = 9;

export const ERROR_UNKNOWN = 1;
export const ERROR_BROWSER = 2;

export const STEPS = 10;

export const BUTTON_AVAILABLE = {
  ["" + INITIAL_STEP]: true,
  ["" + CONNECT_STEP]: true,
  ["" + CONNECT_TO_BOOTLOADER_STEP]: true,
  ["" + CONNECT_TO_FIRMWARE_STEP]: true,
  ["" + UNPLUG_STEP]: true,
  ["" + REPLUG_STEP]: true,
};

const STEP_DELAY = 500;

const sleep = async (delay: number) =>
  new Promise((resolve, reject) => setTimeout(resolve, delay));

export class Workflow {
  private serial: LidSerial = new LidSerial();
  private callbacks: Record<string, Callback> = {};
  private step: number = -1;
  private firmware: FirmwareSpec | null = null;

  private initSerial() {
    this.serial = new LidSerial();
    this.serial.on("progress", ({ parsedData }) =>
      this.updateProgress(((parsedData?.progress as number) || 0) / 100)
    );
  }

  start() {
    this.step = 0;
    this.resetStep();
    this.initSerial();
    if (!this.serial.valid) {
      this.emitError(ERROR_BROWSER);
    }
  }

  next() {
    switch (this.step) {
      case INITIAL_STEP:
        this.resetStep(CONNECT_STEP);
        break;
      case CONNECT_STEP:
        this.connect();
        break;
      case CONNECT_TO_BOOTLOADER_STEP:
        this.connectToBoolader();
        break;
      case CONNECT_TO_FIRMWARE_STEP:
        this.connectToFirmware();
        break;
      case UNPLUG_STEP:
        this.replugDevice();
        break;
      case REPLUG_STEP:
        this.connectToBoolader();
        break;
    }
  }

  private async connect() {
    try {
      try {
        await this.serial.open();
      } catch (error) {
        if ((error as DOMException).code === 8) {
          this.start();
          return;
        } else {
          throw error;
        }
      }
      await this.updateStep(CONNECTED_STEP);
      await sleep(STEP_DELAY);
      this.updateProgress(0.2);

      // Try to connect
      let mode: "standard" | "bootload";
      try {
        const connectResult = await this.serial.execute(
          Commands.BOOTLOADER_CHECK
        );
        mode = "bootload";
      } catch (error) {
        const connectResult = await this.serial.execute(Commands.CONNECT);
        mode = "standard";
      }

      this.updateProgress(0.4);

      // Check for firmware
      this.firmware = await this.checkAvailableFirmware();

      this.updateProgress(0.6);

      // Jump to bootloading phase if the device is already in bootloader mode
      if (mode === "bootload") {
        await this.bootload();
        return;
      }

      // Read existing firmware version. At this point we are sure that the mode is standard
      const key = await this.readVersionKey();

      this.updateProgress(0.8);

      if (this.firmware && key < this.firmware.key) {
        await sleep(STEP_DELAY);

        // Fix for new motherboard not disconnecting properly after bootloading
        try {
          await this.serial.execute(Commands.SWITCH_TO_BOOTLOAD);
        } catch (error) {
          await sleep(3000);
        }

        if (this.serial.newMobo && key < 2089) {
          await this.updateStep(UNPLUG_STEP);
          return;
        }

        await this.serial.close();
        this.serial = new LidSerial();

        await this.updateStep(CONNECT_TO_BOOTLOADER_STEP);
      } else {
        await this.forceDisconnect();
        await this.updateStep(DONE_STEP, "pristine", 1);
      }
    } catch (error) {
      await this.forceDisconnect();
      console.error(error);
      this.emitError();
    }
  }

  private async checkAvailableFirmware() {
    return fetch(`${configProvider("MODEL_API_URL")}/last`).then((response) =>
      response.json()
    );
  }

  private async connectToBoolader() {
    try {
      this.initSerial();
      await this.serial.open(57600);
      await this.bootload();
    } catch (error) {
      console.error(error);
      this.emitError();
    }
  }

  private async bootload() {
    try {
      if (!this.firmware) {
        throw new Error("No firmware available");
      }

      await this.updateStep(BOOTLOAD_STEP, "hex");
      const hex = await loadHex(this.firmware.url);

      await this.updateBranch("inst");
      await this.serial.bootload(hex);
      await sleep(1000);

      await this.serial.close();
      await this.updateStep(CONNECT_TO_FIRMWARE_STEP);
    } catch (error) {
      console.error(error);
      this.emitError();
    }
  }

  private async connectToFirmware() {
    try {
      this.initSerial();
      try {
        await this.serial.open();
      } catch (error) {
        console.log("openError: ", error as DOMException);

        if ((error as DOMException).code === 8) {
          return;
        } else {
          throw error;
        }
      }

      await this.updateStep(VERIFY_FIRMWARE_STEP);
      this.updateProgress(0.25);
      await this.serial.execute(Commands.CONNECT);
      this.updateProgress(0.5);
      const key = await this.readVersionKey();
      this.updateProgress(0.75);

      if (key != this.firmware?.key) {
        await this.forceDisconnect();
        throw Error("Version mismatch after bootload");
      }

      await this.forceDisconnect();
      await this.updateStep(DONE_STEP, "", 1);
    } catch (error) {
      await this.forceDisconnect();
      console.error(error);
      this.emitError();
    }
  }

  private async replugDevice() {
    try {
      await this.updateStep(REPLUG_STEP);
    } catch (error) {
      console.error(error);
      this.emitError();
    }
  }

  private async readVersionKey() {
    // Read existing firmware version
    const { parsedData } = await this.serial.execute(Commands.GET_VERSION);
    const major = parsedData?.major ? parseInt(`${parsedData?.major}`, 16) : 0;
    const minor = parsedData?.minor ? parseInt(`${parsedData?.minor}`, 16) : 0;
    const key = major * 1000 + minor;

    return key;
  }

  private async forceDisconnect() {
    let iteration = 0;
    while (iteration < 3) {
      try {
        await sleep(1000);
        await this.serial.execute(Commands.DISCONNECT);
        await sleep(1500);
        await this.serial.close();
        await sleep(1000);
        return;
      } catch (error) {
        console.error("Error while closing port. Retry: " + (iteration + 1));
      } finally {
        iteration++;
      }
    }
  }

  on(event: string, callback: Callback) {
    this.callbacks[event] = callback;
  }

  private resetStep(step: number = INITIAL_STEP) {
    this.step = step;
    this.emitState(this.step, 0);
  }

  private async updateStep(step: number, branch?: string, progress?: number) {
    this.emitState(this.step, 1);
    await sleep(STEP_DELAY);
    this.step = step;
    this.emitState(step, progress || 0, branch);
  }

  private async updateBranch(branch: string, progress: number = 0) {
    await sleep(STEP_DELAY);
    this.emitState(this.step, progress, branch);
  }

  private updateProgress(progress: number) {
    this.emitState(this.step, progress);
  }

  private emitState(step: number, progress: number, branch?: string) {
    this.callbacks["state"] &&
      this.callbacks["state"]({ step, progress, branch });
  }

  private emitError(errno: number = ERROR_UNKNOWN) {
    this.callbacks["error"] &&
      this.callbacks["error"]({ step: errno, progress: 0 });
  }
}

export type StepState = {
  step: number;
  progress: number;
  branch?: string;
};

export type FirmwareSpec = {
  major: number;
  minor: number;
  url: string;
  key: number;
};

type Callback = (status: StepState) => void;
