import { Command } from './serial';
import * as Names from './names';
import InputBuffer from './inputbuffer';

const encoder = new TextEncoder();
const decoder = new TextDecoder('ascii');

const FRAME_END = 0x21; //!

/*****************************
 * Utility functions
 */
const requiresFrame = () => (data : Uint8Array) => {
  const frameEndIndex = data.findIndex(byte => byte === FRAME_END);

  return frameEndIndex === -1 ? -1 : frameEndIndex + 1;
}

const requiresExactFrame = (required : Uint8Array) => (data : Uint8Array) => {
  return data.length === required.length && data.every((byte, index) => required[index] === byte) ? required.length : -1;
}

const requiresFrameAndPayload = (payloadLength : number) => (data : Uint8Array) => {
  const [frame, payload] = splitFrameAndPayload(data);

  if(!frame || !frame.length || !payload || payload.length < payloadLength) {
    return -1;
  }

  return frame.length + payloadLength;
}

const requiresPayload = (payloadLength : number) => (data : Uint8Array) => {
  return data.length >= payloadLength ? payloadLength : -1;
}

const splitFrameAndPayload = (data : Uint8Array) : [Uint8Array, Uint8Array] | [null,  null] => {
  const frameEndIndex = data.findIndex(byte => byte === FRAME_END);

  if(frameEndIndex === -1) {
    return [null, null];
  }

  return [data.slice(0, frameEndIndex + 1), data.slice(frameEndIndex + 1)];
}


/*****************************
 * Commands catalog
 */
const Comamnds : Record<string, Command> = {
  [Names.CONNECT] : {
    name : Names.CONNECT,
    onWrite : () => encoder.encode('*3100!'),
    onData : requiresExactFrame(encoder.encode('*3100!'))
  },
  [Names.DISCONNECT] : {
    name : Names.DISCONNECT,
    onWrite : () => encoder.encode('*3101!'),
    onData : requiresExactFrame(encoder.encode('*3101!'))
  },
  [Names.GET_VERSION] : {
    name : Names.GET_VERSION,
    onWrite : () => encoder.encode('*3406!'),
    onData : requiresFrameAndPayload(50),
    onRead : (data : Uint8Array) => {
      const [frame, payload] = splitFrameAndPayload(data);

      return frame && payload ? {
        done : true,
        data : data.slice(),
        parsedData : {
          major  : payload[0],
          minor  : payload[1].toString(16),
          serial : [...payload.slice(2).values()].map(byte => byte.toString(16).padStart(2, "0").toUpperCase()).join('')
        }
      } : {
        done : true,
        error : 'Unable to parse received data'
      }
    }
  },
  [Names.SWITCH_TO_BOOTLOAD] : {
    name : Names.SWITCH_TO_BOOTLOAD,
    onWrite : () => encoder.encode('*3110!'),
    onData : requiresFrame()
  },


  [Names.BOOTLOADER] : {
    name : Names.BOOTLOADER,
    onWrite : () => new Uint8Array([0x55, 0xf0, 0x0f, 0xaa]),
    onData : (data : Uint8Array) => 0,
    onRead : (data : Uint8Array) => ({}),
    onTimeout : (data : Uint8Array) => ({})
  },
  [Names.BOOTLOADER_CHECK] : {
    name : Names.BOOTLOADER_CHECK,
    onWrite : () => new Uint8Array([0x55, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]),
    onData : requiresPayload(26),
  },
  [Names.BOOTLOADER_SET_ADDRESS] : {
    name : Names.BOOTLOADER_SET_ADDRESS,
    onWrite : () => new Uint8Array([0x55, 0x03, 0xb8, 0x03, 0x55, 0xaa, 0x00, 0x12, 0x00, 0x00]),
    onData : requiresPayload(11)
  },
  [Names.BOOTLOADER_WRITE_CHUNK] : {
    name : Names.BOOTLOADER_WRITE_CHUNK,
    onWrite : (values) => {
      const buffer = new Uint8Array(74); // 4 x 16bytes chunks + 10 byte frame
      const address : string = (values?.address || '') as string;
      const data : string = (values?.data || '') as string;

      const addressBytes = new Uint8Array(address.match(/.{1,2}/g)?.map(value => parseInt(value, 16)) || []);
      const dataBytes = new Uint8Array(data.match(/.{1,2}/g)?.map(value => parseInt(value, 16)) || []);

      buffer.set([0x55, 0x02, 0x40, 0x00, 0x55, 0xaa, addressBytes[1], addressBytes[0], 0x00, 0x00]);
      buffer.set(dataBytes, 10);

      return buffer;
    },
    onData : requiresPayload(11),
    onRead : (data : Uint8Array, values ?: Record<string, string | Uint8Array>) => {
      const address : string = (values?.address || '') as string;
      const addressBytes = new Uint8Array(address.match(/.{1,2}/g)?.map(value => parseInt(value, 16)) || []);
      const expected = [0x55, 0x02, 0x40, 0x00, 0x55, 0xaa, addressBytes[1], addressBytes[0], 0x00, 0x00, 0x01];

      if(data.every((value, index) => value === expected[index])) {
        return {}
      }
      else {
        return {
          error : 'Unexpected response'
        }
      }
    }
  },
  [Names.BOOTLOADER_CLOSING_1] : {
    name : Names.BOOTLOADER_CLOSING_1,
    onWrite : () => new Uint8Array([0x55, 0x08, 0xff, 0xed, 0x00, 0x00, 0x00, 0x12, 0x00, 0x00]),
    onData : requiresPayload(12)
  },
  [Names.BOOTLOADER_CLOSING_2] : {
    name : Names.BOOTLOADER_CLOSING_2,
    onWrite : () => new Uint8Array([0x55, 0x09, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]),
    onData : requiresPayload(4)
  }
};

export default Comamnds;





