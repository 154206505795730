import React from 'react';
import styled from 'styled-components';


const BubbleContainer = styled.div`
  position : absolute;
  width : 6.2rem;
  height : 6.2rem;
  bottom : -3.1rem;
  left : -2.5rem;
  transition : transform 0.8s ease;
  transform-origin : center center;
`;

const BubbleBorder = styled.div`
  position : absolute;
  width : 6.2rem;
  height : 6.2rem;
  top : 0;
  left : 0;
  border-radius : 6.2rem;
  border : 0.2rem solid #222222;
  background : white;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;
  font-size : 2rem;

  transition : border-color 0.8s ease;

  &.error {
    border-color: #CC6666;
  }

  &.ok {
    border-color: #92D3C2;
  }
`;

const BubbleError = styled.div`
  position : absolute;
  width : 6.2rem;
  height : 6.2rem;
  top : 0;
  left : 0;
  border-radius : 6.2rem;
  border : 0.2rem solid #CC6666;
  background-color : #CC6666;
  transform : scale(0);
  transition : transform 0.5s ease;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;

  &.visible {
    transform : scale(1);
  }
`;

const BubbleOK = styled(BubbleError)`
  border : 0.2rem solid #92D3C2;
  background-color : #92D3C2;
`

const Content = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;
  transform : scale(0);
  transition : transform 0.5s ease;

  &.visible {
    transform : scale(1);
  }
`


const Bubble : React.FC<BubbleProps> = ({rotation, type, children, ...props}) => {
  return (
    <BubbleContainer {...props} style={{transform : `rotate(${-rotation}deg)`}}>
      <BubbleBorder className={type}>
        <Content className={type === 'ongoing' ? 'visible' : ''}>· · ·</Content>
      </BubbleBorder>
      <BubbleError className={type === 'error' ? 'visible' : ''}>
        <svg width="2rem" height="1.9rem" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1.70711" y1="1.29289" x2="18.7071" y2="18.2929" stroke="white" stroke-width="2"/>
          <line y1="-1" x2="24.0416" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 19 2)" stroke="white" stroke-width="2"/>
        </svg>
      </BubbleError>
      <BubbleOK className={type === 'ok' ? 'visible' : ''}>
        <svg width="2.9rem" height="2.2rem" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 11.2404L10.6346 20.375L27.9423 1.625" stroke="#FFFDF9" stroke-width="2"/>
        </svg>
      </BubbleOK>
    </BubbleContainer>
  );
}

interface BubbleProps {
  rotation : number;
  type ?: 'error' | 'ok' | 'empty' | 'ongoing';
}

export default Bubble;