import {
  INITIAL_STEP,
  CONNECT_STEP,
  CONNECTED_STEP,
  CONNECT_TO_BOOTLOADER_STEP,
  BOOTLOAD_STEP,
  CONNECT_TO_FIRMWARE_STEP,
  VERIFY_FIRMWARE_STEP,
  DONE_STEP,
  ERROR_BROWSER,
  ERROR_UNKNOWN,
  UNPLUG_STEP,
  REPLUG_STEP,
} from '../workflow/workflow';

export default {
  translation : {
    header : {
      link : 'retour sur mon compte'
    },
    steps : {
      ['step' + INITIAL_STEP]                : '',
      ['step' + CONNECT_STEP]                : 'Etape 1',
      ['step' + CONNECTED_STEP]              : 'Etape 2',
      ['step' + CONNECT_TO_BOOTLOADER_STEP]  : 'Etape 3',
      ['step' + BOOTLOAD_STEP]               : 'Etape 4',
      ['step' + CONNECT_TO_FIRMWARE_STEP]    : 'Etape 5',
      ['step' + VERIFY_FIRMWARE_STEP]        : 'Etape 6',
      ['step' + DONE_STEP]                   : 'Etape 7',
      'step-error'                           : 'Oups !'
    },
    titles : {
      ['title' + INITIAL_STEP]               : `Bienvenue dans l'interface de gestion de votre appareil`,
      ['title' + CONNECT_STEP]               : `Etape 1 : Connexion`,
      ['title' + CONNECTED_STEP]             : `Etape 2 : Recherche de mise à jour`,
      ['title' + CONNECT_TO_BOOTLOADER_STEP] : `Etape 3 : Mise à jour de l’appareil`,
      ['title' + BOOTLOAD_STEP]              : `Etape 4 : Mise à jour de l’appareil`,
      ['title' + CONNECT_TO_FIRMWARE_STEP]   : `Etape 5 : Vérification de l’appareil`,
      ['title' + VERIFY_FIRMWARE_STEP]       : `Etape 6 : Vérification de l’appareil`,
      ['title' + DONE_STEP]                  : `Etape 7 : Félicitations, votre appareil est à jour !`,
      ['title' + UNPLUG_STEP]                : `Deconnexion de l'appareil`,
      ['title' + REPLUG_STEP]                : `Reconnexion de l'appareil`,
      ['title-error' + ERROR_BROWSER]        : `L'interface de gestion de votre appareil est disponible uniquement sur le navigateur Chrome`,
      ['title-error' + ERROR_UNKNOWN]        : `Une erreur est survenue`
    },
    descriptions : {
      ['description' + INITIAL_STEP]               : `Branchez votre appareil sur un port USB de votre ordinateur via son câble d’alimentation, puis cliquez sur le bouton ci-dessous.`,
      ['description' + CONNECT_STEP]               : `Cliquez sur le bouton ci-dessous. Une fenêtre va s’ouvrir, cliquez sur la ligne qui apparait dans la fenêtre, puis sur Connexion.`,
      ['description' + CONNECTED_STEP]             : `Appareil connecté.`,
      ['description' + CONNECT_TO_BOOTLOADER_STEP] : `Une mise à jour est disponible pour votre appareil. Cliquez sur le bouton ci-dessous, une fenêtre va s’ouvrir, cliquez sur la ligne qui apparait dans la fenêtre, puis sur Connexion.`,
      ['description' + BOOTLOAD_STEP + 'hex']      : `Téléchargement du nouveau logiciel en cours.`,
      ['description' + BOOTLOAD_STEP + 'inst']     : `Installation du nouveau logiciel en cours`,
      ['description' + BOOTLOAD_STEP]              : `Installation du nouveau logiciel en cours`,
      ['description' + CONNECT_TO_FIRMWARE_STEP]   : `Pour vérifier la mise à jour de votre appareil, cliquez sur le bouton ci-dessous, une fenêtre va s’ouvrir, cliquez sur la ligne qui apparait dans la fenêtre, puis sur Connexion.`,
      ['description' + VERIFY_FIRMWARE_STEP]       : `Appareil en cours de vérification.`,
      ['description' + DONE_STEP]                  : `Vous pouvez désormais débrancher votre appareil de l’ordinateur et fermer cette fenêtre.`,
      ['description' + DONE_STEP + 'pristine']     : `Vous pouvez désormais débrancher votre appareil de l’ordinateur et fermer cette fenêtre.`,
      ['description' + UNPLUG_STEP]                : `Veuillez débrancher l'appareil de l'ordinateur.</br> Après l'avoir débranché, cliquer sur le bouton ci-dessous :`,
      ['description' + REPLUG_STEP]                : `Veuillez rebrancher l'appareil à l'ordinateur.</br> Une fois rebranché,veuillez cliquer sur le bouton ci-dessous :`,
      'description-error'                          : `La mise à jour n’a pas fonctionné. Veuillez contactez Lightinderm pour résourdre le problème.`,
      'description-error-browser'                  : `Veuillez télécharger le navigateur Chrome pour accéder à l'interface de gestion votre appareil.`
    },
    buttons : {
      ['button' + INITIAL_STEP]               : `COMMENCER`,
      ['button' + CONNECT_STEP]               : `CONNECTER MON APPAREIL`,
      ['button' + CONNECTED_STEP]             : `CONNECTER MON APPAREIL`,
      ['button' + CONNECT_TO_BOOTLOADER_STEP] : 'METTRE A JOUR MON APPAREIL',
      ['button' + BOOTLOAD_STEP]              : 'METTRE A JOUR MON APPAREIL',
      ['button' + CONNECT_TO_FIRMWARE_STEP]   : 'VERIFIER MON APPAREIL',
      ['button' + VERIFY_FIRMWARE_STEP]       : 'VERIFIER MON APPAREIL',
      ['button' + UNPLUG_STEP]                : 'SUIVANT',
      ['button' + REPLUG_STEP]                : 'MON APPAREIL EST REBRANCHE',
      'button-error'                          : 'recommencer'
    },
    errorBottomLine : 'Si le problème persiste, <a href="mailto:contact@lightinderm.com">contactez-nous</a>',
    mobileContent : 'La mise à jour n’est pas réalisable depuis le mobile, veuillez passer sur desktop'
  }
}
