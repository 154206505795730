import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const StyledButton = styled.button`
  width : 38.6rem;
  height : 4rem;
  background-color : #222222;
  border-radius : 0.2rem;
  color : white;
  display : flex;
  flex-direction : row;
  justify-content : space-between;
  align-items : center;
  border : 0.2rem solid #222222;
  padding : 0 1.9rem;
  text-transform : uppercase;
  font-size : 1.2rem;

  &:active {
    background-color : white;
    color : #222222;
  }

  opacity : 0;
  transition : opacity 0.8s ease;

  &.visible {
    opacity : 1;
  }
`;

const Button : React.FC<ButtonProps> = ({children, visible, className, ...props}) => {
  return (
    <StyledButton {...props} disabled={!visible} className={classnames([className, visible ? 'visible' : ''])}>
      {children}
      <svg width="28" height="8" viewBox="0 0 28 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9379 6.36395L22.8956 7.07104L27.6841 3.53552L22.8957 -1.73959e-06L21.938 0.70709L25.1398 3.07104L0.596364 3.07104L0.596363 4.07104L25.0434 4.07104L21.9379 6.36395Z" fill="white"/>
      </svg>
    </StyledButton>
  )
}

interface ButtonProps {
  onClick : () => void;
  visible ?: boolean;
  className ?: string;
}

export default Button;