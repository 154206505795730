const encoder = new TextEncoder();
const decoder = new TextDecoder('ascii');

export const CONNECT = 'connect';
export const DISCONNECT = 'disconect';
export const GET_VERSION = 'get-version';
export const SWITCH_TO_BOOTLOAD = 'switch-to-bootload';

export const BOOTLOADER = 'bootloader';
export const BOOTLOADER_CHECK = 'bootloader-check';
export const BOOTLOADER_SET_ADDRESS = 'bootloader-set-address';
export const BOOTLOADER_WRITE_CHUNK = 'bootloader-write-chunk';
export const BOOTLOADER_CLOSING_1 = 'bootloader-closing-1';
export const BOOTLOADER_CLOSING_2 = 'bootloader-closing-2';