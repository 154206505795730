import { HexFile } from "./types";

const
  SIZE_START      = 0,
  SIZE_LENGTH     = 2,
  ADDERSS_START   = SIZE_START + SIZE_LENGTH,
  ADDERSS_LENGTH  = 4,
  TYPE_START      = ADDERSS_START + ADDERSS_LENGTH,
  TYPE_LENGTH     = 2,
  DATA_START      = TYPE_START + TYPE_LENGTH,
  CHECKSUM_LENGTH = 2;

export async function loadHex(binaryUrl : string) {

  const response = await fetch(binaryUrl)

  if(response.status === 200 && response.body) {
    const reader = response.body.getReader();
    let buffer : string = '';
    const decoder : TextDecoder = new TextDecoder('ascii');

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        if (value) {
          buffer += decoder.decode(value);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      reader.releaseLock();
    }

    const content : HexFile =
      buffer
      .replace(/[\n\r\t\s]+/g, ',')
      .replace(/:/g, '')
      .split(',')
      .map(row => {
        const size         = row.slice(SIZE_START, SIZE_START+SIZE_LENGTH);
        const parsedSize   = parseInt(size, 16);
        const address      = row.slice(ADDERSS_START, ADDERSS_START+ADDERSS_LENGTH);
        const addressBytes = new Uint8Array(address.match(/.{1,2}/g)?.map(value => parseInt(value, 16)) || []);
        const type         = row.slice(TYPE_START, TYPE_START+TYPE_LENGTH);
        const data         = row.slice(DATA_START, DATA_START+parsedSize*2);
        const dataBytes    = new Uint8Array(data.match(/.{1,2}/g)?.map(value => parseInt(value, 16)) || []);
        const checksum     = row.slice(DATA_START+parsedSize*2, DATA_START+parsedSize*2+CHECKSUM_LENGTH);

        return {
          row, size, parsedSize, address, addressBytes, type, data, dataBytes, checksum
        };
      })

    return content;
  }
  else {
    throw new Error("Unable to fetch file");
  }
}