import React from 'react';


const RemResizer : React.FC<RemResizerProps>= ({dimension, max, min, center, steps}) => {
  if(max < 1 || min < 1 || max <= min || center < min || center > max || steps < 1) {
    return null;
  }

  const step = (max-min)/steps;
  const minValue = 62.5/center*min;
  const maxValue = 62.5/center*max;
  const valueStep = (maxValue - minValue)/steps;

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: [-1, ...new Array(steps).keys(), steps]
          .map(index =>
            index === -1 ?
              `
                @media screen and (max-${dimension}: ${min}px) {
                  html {font-size: ${minValue}%;}
                }
              ` :
            index === steps ?
              `
                @media screen and (min-${dimension}: ${max}px) {
                  html {font-size: ${maxValue}%;}
                }
              ` :
              `
                @media screen and (min-${dimension}: ${min+index*step}px) and (max-${dimension}: ${min+(index+1)*step}px) {
                  html {font-size: ${minValue+valueStep*index}%;}
                }
              `
          ).join('')
      }}
    />
  )
}

interface RemResizerProps {
  dimension : 'width' | 'height',
  max    : number,
  min    : number,
  center : number,
  steps  : number
}

export default RemResizer;